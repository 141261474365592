import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import Hamburger from 'components/hamburger/hamburger';
import MobileMenu from 'components/mobileMenu/mobileMenu';

const HeaderWrapper = styled.div`
  width: 100vw;
  height: 100px;
  display: none;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;

  @media (max-width: 850px) {
    display: flex;
  }
`;

const LogoContainer = styled(Link)`
  width: 180px;
  height: 130px;
  position: relative;
  cursor: pointer;
`;

const Logo = styled(Image)`
  width: 180px;
  height: auto;
  z-index: 9999;
  position: absolute;
  top: -20px;
`;

const StyledHamburger = styled(Hamburger)`
  position: absolute;
  top: 30px;
  right: 10px;
`;

const MobileHeader = () => {
  const [isMenuOpen, setMenuState] = useState({ initialState: false });

  const toggleMobileMenu = () => {
    setMenuState(!isMenuOpen);
  };

  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fixed(width: 180, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <>
      <HeaderWrapper>
        <LogoContainer to="/">
          <Logo fixed={logo.sharp.fixed} />
        </LogoContainer>
        <MobileMenu isOpen={isMenuOpen} />
      </HeaderWrapper>
      <StyledHamburger onClick={toggleMobileMenu} isOpen={isMenuOpen} />
    </>
  );
};

export default MobileHeader;
