import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import Ratchet from 'assets/images/ratchet.png';

const HeaderItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const HeaderLink = styled(Link)`
  padding: 15px 20px;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  transition: color 0.3s ease-in-out;

  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const HeaderDeactiveLink = styled.p`
  padding: 15px 20px;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  ::after {
    content: '';
    background-image: url(${Ratchet});
    background-size: 50px auto;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 2px;
    left: -38px;
    z-index: 1;
    transform-origin: 'center center';
    animation: rotate 10s linear infinite;
    display: none;

    @media (min-width: 850px) {
      display: block;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const HeaderItems = () => {
  return (
    <>
      <Location>
        {({ location }) => {
          if (location.pathname === '/') {
            return (
              <HeaderItem>
                <HeaderDeactiveLink>Home</HeaderDeactiveLink>
              </HeaderItem>
            );
          }
          if (location.pathname !== '/') {
            return (
              <HeaderItem>
                <HeaderLink to="/">Home</HeaderLink>
              </HeaderItem>
            );
          }
          return null;
        }}
      </Location>
      <Location>
        {({ location }) => {
          if (
            location.pathname === '/o-nas' ||
            location.pathname === '/o-nas/'
          ) {
            return (
              <HeaderItem>
                <HeaderDeactiveLink>O nas</HeaderDeactiveLink>
              </HeaderItem>
            );
          }
          if (
            location.pathname !== '/o-nas' ||
            location.pathname !== '/o-nas/'
          ) {
            return (
              <HeaderItem>
                <HeaderLink to="/o-nas">O nas</HeaderLink>
              </HeaderItem>
            );
          }
          return null;
        }}
      </Location>
      <Location>
        {({ location }) => {
          if (
            location.pathname === '/oferta' ||
            location.pathname === '/oferta/'
          ) {
            return (
              <HeaderItem>
                <HeaderDeactiveLink>Oferta</HeaderDeactiveLink>
              </HeaderItem>
            );
          }
          if (
            location.pathname !== '/oferta' ||
            location.pathname !== '/oferta/'
          ) {
            return (
              <HeaderItem>
                <HeaderLink to="/oferta">Oferta</HeaderLink>
              </HeaderItem>
            );
          }
          return null;
        }}
      </Location>
      <Location>
        {({ location }) => {
          if (
            location.pathname === '/cennik' ||
            location.pathname === '/cennik/'
          ) {
            return (
              <HeaderItem>
                <HeaderDeactiveLink>Cennik</HeaderDeactiveLink>
              </HeaderItem>
            );
          }
          if (
            location.pathname !== '/cennik' ||
            location.pathname !== '/cennik/'
          ) {
            return (
              <HeaderItem>
                <HeaderLink to="/cennik">Cennik</HeaderLink>
              </HeaderItem>
            );
          }
          return null;
        }}
      </Location>
      <Location>
        {({ location }) => {
          if (
            location.pathname === '/realizacje' ||
            location.pathname === '/realizacje/'
          ) {
            return (
              <HeaderItem>
                <HeaderDeactiveLink>Realizacje</HeaderDeactiveLink>
              </HeaderItem>
            );
          }
          if (
            location.pathname !== '/realizacje' ||
            location.pathname !== '/realizacje/'
          ) {
            return (
              <HeaderItem>
                <HeaderLink to="/realizacje">Realizacje</HeaderLink>
              </HeaderItem>
            );
          }
          return null;
        }}
      </Location>
      <Location>
        {({ location }) => {
          if (
            location.pathname === '/kontakt' ||
            location.pathname === '/kontakt/'
          ) {
            return (
              <HeaderItem>
                <HeaderDeactiveLink>kontakt</HeaderDeactiveLink>
              </HeaderItem>
            );
          }
          if (
            location.pathname !== '/kontakt' ||
            location.pathname !== '/kontakt/'
          ) {
            return (
              <HeaderItem>
                <HeaderLink to="/kontakt">Kontakt</HeaderLink>
              </HeaderItem>
            );
          }
          return null;
        }}
      </Location>
    </>
  );
};

export default HeaderItems;
