import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const StyledWrapper = styled.div`
  width: 450px;
  height: 23px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    flex-flow: column;
    justify-content: center;
  }
`;

const ContactLink = styled.a`
  text-decoration: none;
  transition: transform 0.3s ease-in-out;
  font-size: ${({ theme }) => theme.font.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ContactLogo = styled(Image)`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
`;

const ContactIcons = () => {
  const { mail, clock, phone } = useStaticQuery(graphql`
    query {
      mail: file(relativePath: { eq: "mail.png" }) {
        sharp: childImageSharp {
          fixed(width: 20, quality: 100) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
      clock: file(relativePath: { eq: "clock.png" }) {
        sharp: childImageSharp {
          fixed(width: 20, quality: 100) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
      phone: file(relativePath: { eq: "phone.png" }) {
        sharp: childImageSharp {
          fixed(width: 20, quality: 100) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <ContactLink href="mailto:office@factoryoflook.com">
        <ContactLogo fixed={mail.sharp.fixed} />
        office@factoryoflook.com
      </ContactLink>
      <ContactLink as="p">
        <ContactLogo fixed={clock.sharp.fixed} />
        08-16
      </ContactLink>
      <ContactLink href="tel:+717924569">
        <ContactLogo fixed={phone.sharp.fixed} />
        +71 792 45 69
      </ContactLink>
    </StyledWrapper>
  );
};

export default ContactIcons;
