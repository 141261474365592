import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Location } from '@reach/router';

const SectionWrapper = styled.div`
  width: 100%;
  height: 370px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;

  @media (min-width: 850px) {
    display: none;
  }
`;

const FooterTextContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const FooterText = styled.a`
  padding: 2.5px 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;

const FooterImage = styled(Image)`
  width: 100%;
  height: 300px;
`;

const FooterMobile = () => {
  const {
    home,
    aboutUs,
    offer,
    pricing,
    portfolio,
    contact,
  } = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "home-sidebar.png" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutUs: file(relativePath: { eq: "aboutUS-sidebar.jpg" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      offer: file(relativePath: { eq: "offer-sidebar.png" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pricing: file(relativePath: { eq: "pricing-sidebar.png" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portfolio: file(relativePath: { eq: "portfolio-sidebar.png" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contact: file(relativePath: { eq: "contact-sidebar.jpg" }) {
        sharp: childImageSharp {
          fluid(
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <SectionWrapper>
      <FooterTextContainer>
        <FooterText href="mailto:office@factoryoflook.com">
          office@factoryoflook.com
        </FooterText>
        <FooterText href="tel:+717924569">+71 792 45 69</FooterText>
      </FooterTextContainer>
      <Location>
        {({ location }) => {
          if (location.pathname === '/') {
            return <FooterImage fluid={home.sharp.fluid} />;
          }
          if (
            location.pathname === '/o-nas' ||
            location.pathname === '/o-nas/'
          ) {
            return <FooterImage fluid={aboutUs.sharp.fluid} />;
          }
          if (
            location.pathname === '/oferta' ||
            location.pathname === '/oferta/'
          ) {
            return <FooterImage fluid={offer.sharp.fluid} />;
          }
          if (
            location.pathname === '/cennik' ||
            location.pathname === '/cennik/'
          ) {
            return <FooterImage fluid={pricing.sharp.fluid} />;
          }
          if (
            location.pathname === '/realizacje' ||
            location.pathname === '/realizacje/'
          ) {
            return <FooterImage fluid={portfolio.sharp.fluid} />;
          }
          if (
            location.pathname === '/kontakt' ||
            location.pathname === '/kontakt/'
          ) {
            return <FooterImage fluid={contact.sharp.fluid} />;
          }
          return <FooterImage fluid={pricing.sharp.fluid} />;
        }}
      </Location>
    </SectionWrapper>
  );
};

export default FooterMobile;
