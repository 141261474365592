import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
      font-size: 62.5%;
    }

    body {
        margin: 0;
        font-family: "Century Gothic", sans-serif;
        font-size: 16px;
        line-height: 1.15;
        overflow:hidden;
    }

    .ReactModal__Overlay {
      z-index: 9998
    }
`;

export const SiteWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 550px);

  @media (max-width: 1350px) {
    width: calc(100% - 150px);
  }

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (min-width: 1100px) {
    @media (min-height: 600px) {
      height: 969px !important;
    }
  }

  @media (min-height: 800px) {
    height: 100vh !important;
  }
`;

export const HomeContent = styled.div`
  width: 100%;
  height: 870px;
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 620px !important;
    }
  }

  @media (min-resolution: 120dpi) {
    height: 620px;
  }

  @media (min-height: 720px) {
    @media (max-width: 850px) {
      height: 650px !important;
    }
  }

  @media (min-height: 750px) {
    @media (max-width: 850px) {
      height: 720px !important;
    }
  }

  @media (min-height: 820px) {
    @media (max-width: 850px) {
      height: 800px !important;
    }
  }

  @media (min-height: 900px) {
    @media (max-width: 850px) {
      height: 900px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 850px) {
      height: 930px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 1050px) {
      height: 1280px;
    }
  }

  @media (min-width: 1200px) {
    @media (max-height: 750px) {
      @media (max-resolution: 110dpi) {
        height: 560px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const AboutUsContent = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 870px;

  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 650px !important;
    }
  }

  @media (min-resolution: 120dpi) {
    height: 700px;
  }

  @media (min-height: 720px) {
    @media (max-width: 850px) {
      height: 650px !important;
    }
  }

  @media (min-height: 750px) {
    @media (max-width: 850px) {
      height: 720px !important;
    }
  }

  @media (min-height: 820px) {
    @media (max-width: 850px) {
      height: 800px !important;
    }
  }

  @media (min-height: 900px) {
    @media (max-width: 850px) {
      height: 900px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 850px) {
      height: 920px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 1050px) {
      height: 1280px;
    }
  }

  @media (min-width: 1200px) {
    @media (max-height: 750px) {
      @media (max-resolution: 100dpi) {
        height: 560px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const OfferContent = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 840px;

  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 650px !important;
    }
  }

  @media (min-resolution: 120dpi) {
    height: 680px;
  }

  @media (min-height: 720px) {
    @media (max-width: 850px) {
      height: 650px !important;
    }
  }

  @media (min-height: 750px) {
    @media (max-width: 850px) {
      height: 720px !important;
    }
  }

  @media (min-height: 820px) {
    @media (max-width: 850px) {
      height: 800px !important;
    }
  }

  @media (min-height: 900px) {
    @media (max-width: 850px) {
      height: 900px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 850px) {
      height: 920px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 1050px) {
      height: 1295px;
    }
  }

  @media (min-width: 1200px) {
    @media (max-height: 750px) {
      @media (max-resolution: 100dpi) {
        height: 560px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const PricingContent = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 910px;

  @media (min-width: 1400px) {
    height: 1142px;
  }

  @media (min-width: 1550px) {
    height: 1030px;
  }

  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 650px !important;
    }
  }

  @media (max-width: 1350px) {
    @media (min-resolution: 120dpi) {
      height: 700px !important;
    }
  }

  @media (max-width: 1100px) {
    @media (min-resolution: 120dpi) {
      height: 755px !important;
    }
  }

  @media (max-width: 970px) {
    @media (min-resolution: 120dpi) {
      height: 620px !important;
    }
  }

  @media (min-resolution: 120dpi) {
    height: 930px;
  }

  @media (min-height: 720px) {
    @media (max-width: 850px) {
      height: 650px !important;
    }
  }

  @media (min-height: 750px) {
    @media (max-width: 850px) {
      height: 720px !important;
    }
  }

  @media (min-height: 820px) {
    @media (max-width: 850px) {
      height: 800px !important;
    }
  }

  @media (min-height: 900px) {
    @media (max-width: 850px) {
      height: 900px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 850px) {
      height: 920px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 1050px) {
      height: 1295px;
    }
  }

  @media (min-width: 1200px) {
    @media (max-height: 750px) {
      @media (max-resolution: 100dpi) {
        height: 600px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const PortfolioContent = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 1100px) {
    @media (min-height: 600px) {
      height: 530px !important;
      overflow-y: auto !important;
    }
  }

  @media (min-resolution: 120dpi) {
    height: 622px !important;
  }

  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 650px !important;
    }
  }

  @media (min-height: 800px) {
    height: calc(100vh - 100px) !important;
  }

  @media (min-width: 850px) {
    overflow-y: hidden;
  }

  @media (min-height: 1000px) {
    overflow-y: auto !important;
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const ContactContent = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 837px;

  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 650px !important;
    }
  }

  @media (min-resolution: 120dpi) {
    height: 680px;
  }

  @media (min-height: 720px) {
    @media (max-width: 850px) {
      height: 650px !important;
    }
  }

  @media (min-height: 750px) {
    @media (max-width: 850px) {
      height: 720px !important;
    }
  }

  @media (min-height: 820px) {
    @media (max-width: 850px) {
      height: 800px !important;
    }
  }

  @media (min-height: 900px) {
    @media (max-width: 850px) {
      height: 900px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 850px) {
      height: 920px !important;
    }
  }

  @media (min-height: 1000px) {
    @media (max-width: 1050px) {
      height: 1295px;
    }
  }

  @media (min-width: 1200px) {
    @media (max-height: 750px) {
      @media (max-resolution: 100dpi) {
        height: 560px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.7rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const NotFoundContent = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 1100px) {
    @media (min-height: 600px) {
      height: 650px !important;
      overflow-y: auto !important;
    }
  }
  @media (min-resolution: 120dpi) {
    height: 650px !important;
  }
  @media (max-width: 850px) {
    @media (min-resolution: 120dpi) {
      height: 650px !important;
    }
  }
  @media (min-height: 800px) {
    height: calc(100vh - 100px) !important;
    overflow-y: hidden !important;
  }
  @media (min-width: 850px) {
    overflow-y: hidden;
  }
  @media (min-height: 1000px) {
    overflow-y: auto !important;
  }
  ::-webkit-scrollbar {
    width: 0.7rem;
  }
  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;
