import styled from 'styled-components';

export const SquareBox = styled.div`
  width: 100%;
  height: 40px;
  overflow: hidden;

  @media (min-width: 1700px) {
    width: 80%;
  }
`;

export const Square = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
`;
