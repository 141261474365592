import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledHamburger = styled.button`
  padding: 15px;
  border: none;
  background: none;
  position: relative;
  right: 10px;
  z-index: 9999;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`;

const InnerHamburger = styled.div`
  position: relative;
  width: 20px;
  height: 2px;
  background: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.black : 'transparent'};
  transition: background-color 0.15s ease-in;

  ::after,
  ::before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.colors.black};
    transition: transform 0.25s 0.15s ease-in-out;
  }

  ::before {
    top: -6px;
    transform: translateY(${({ isOpen }) => (isOpen ? '0' : '6px')})
      rotate(${({ isOpen }) => (isOpen ? '0' : '45deg')});
  }

  ::after {
    top: 6px;
    transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-6px')})
      rotate(${({ isOpen }) => (isOpen ? '0' : '-45deg')});
  }
`;

const Hamburger = ({ isOpen, ...props }) => (
  <StyledHamburger {...props}>
    <InnerHamburger isOpen={isOpen} />
  </StyledHamburger>
);

Hamburger.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Hamburger;
