import React, { createRef, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { SquareBox, Square } from './squareAnimationNoMargin.styled';

const SquareAnimationNoMargin = () => {
  const squareRef = useRef([...Array(18)].map(() => createRef()));

  const tl = gsap.timeline({ repeat: -1, yoyo: true });

  useEffect(() => {
    tl.to(squareRef.current[0].current, 0.025, {
      backgroundColor: '#942985',
      opacity: 0.2,
    })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[0].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[1].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[2].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[3].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[4].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[5].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[6].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[7].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[8].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[9].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[10].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[11].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[12].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 1,
      })
      .to(squareRef.current[13].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.8,
      })
      .to(squareRef.current[14].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.6,
      })
      .to(squareRef.current[15].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.4,
      })
      .to(squareRef.current[16].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0.2,
      })
      .to(squareRef.current[17].current, 0.025, {
        backgroundColor: '#942985',
        opacity: 0,
      });
  });

  return (
    <SquareBox>
      <Square ref={squareRef.current[0]} />
      <Square ref={squareRef.current[1]} />
      <Square ref={squareRef.current[2]} />
      <Square ref={squareRef.current[3]} />
      <Square ref={squareRef.current[4]} />
      <Square ref={squareRef.current[5]} />
      <Square ref={squareRef.current[6]} />
      <Square ref={squareRef.current[7]} />
      <Square ref={squareRef.current[8]} />
      <Square ref={squareRef.current[9]} />
      <Square ref={squareRef.current[10]} />
      <Square ref={squareRef.current[11]} />
      <Square ref={squareRef.current[12]} />
      <Square ref={squareRef.current[13]} />
      <Square ref={squareRef.current[14]} />
      <Square ref={squareRef.current[15]} />
      <Square ref={squareRef.current[16]} />
      <Square ref={squareRef.current[17]} />
    </SquareBox>
  );
};

export default SquareAnimationNoMargin;
