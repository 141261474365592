import React from 'react';
import styled from 'styled-components';
import SocialIcons from 'components/socialIcons/socialIcons';
import ContactIcons from 'components/contactIcons/contactIcons';

const ContactHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 50px);
  height: 100px;
  margin-left: 50px;

  @media (max-width: 850px) {
    display: none;
  }
`;

const ContactHeader = () => {
  return (
    <ContactHeaderWrapper>
      <ContactIcons />
      <SocialIcons />
    </ContactHeaderWrapper>
  );
};

export default ContactHeader;
