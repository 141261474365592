import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import HeaderItems from 'components/headerItems/headerItems';
import { Location } from '@reach/router';

const HeaderWrapper = styled.div`
  width: 150px;
  background: ${({ theme }) => theme.colors.primary};
  position: relative;
`;

const Logo = styled(Image)`
  position: absolute;
  width: 220px;
  height: auto;
  top: 0;
  left: -35px;
  z-index: 3;
  cursor: pointer;
`;

const HeaderList = styled.ul`
  width: 100%;
  margin-top: 50px;
  list-style: none;
`;

const LogoLink = styled(Link)`
  cursor: pointer;
`;

const Header = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fixed(width: 220, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <HeaderWrapper>
      <Location>
        {({ location }) => {
          if (location.pathname === '/') {
            return <Logo fixed={logo.sharp.fixed} />;
          }
          return (
            <LogoLink to="/">
              <Logo fixed={logo.sharp.fixed} />
            </LogoLink>
          );
        }}
      </Location>
      <HeaderList>
        <HeaderItems />
      </HeaderList>
    </HeaderWrapper>
  );
};

export default Header;
