import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Location } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import Header from 'components/header/header';

const SideBarWrapper = styled.div`
  display: flex;
  width: 550px;
  justify-content: flex-start;

  @media (max-width: 1350px) {
    width: 150px;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const SideBarImage = styled(Image)`
  width: 400px;
  height: auto;
  display: ${({ active }) => (active ? 'none' : 'block')};
  position: relative;
  z-index: 2;
`;

const sideBar = () => {
  const {
    home,
    aboutUs,
    offer,
    pricing,
    portfolio,
    contact,
  } = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "home-sidebar.png" }) {
        sharp: childImageSharp {
          fixed(
            width: 400
            height: 1056
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      aboutUs: file(relativePath: { eq: "aboutUS-sidebar.jpg" }) {
        sharp: childImageSharp {
          fixed(
            width: 400
            height: 1056
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      offer: file(relativePath: { eq: "offer-sidebar.png" }) {
        sharp: childImageSharp {
          fixed(
            width: 400
            height: 1056
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      pricing: file(relativePath: { eq: "pricing-sidebar.png" }) {
        sharp: childImageSharp {
          fixed(
            width: 400
            height: 1056
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      portfolio: file(relativePath: { eq: "portfolio-sidebar.png" }) {
        sharp: childImageSharp {
          fixed(
            width: 400
            height: 1056
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      contact: file(relativePath: { eq: "contact-sidebar.jpg" }) {
        sharp: childImageSharp {
          fixed(
            width: 400
            height: 1056
            quality: 100
            duotone: { highlight: "#942985", opacity: 49, shadow: "#942985" }
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <SideBarWrapper>
      <Location>
        {({ location }) => {
          if (location.pathname === '/') {
            return <SideBarImage fixed={home.sharp.fixed} />;
          }
          if (
            location.pathname === '/o-nas' ||
            location.pathname === '/o-nas/'
          ) {
            return <SideBarImage fixed={aboutUs.sharp.fixed} />;
          }
          if (
            location.pathname === '/oferta' ||
            location.pathname === '/oferta/'
          ) {
            return <SideBarImage fixed={offer.sharp.fixed} />;
          }
          if (
            location.pathname === '/cennik' ||
            location.pathname === '/cennik/'
          ) {
            return <SideBarImage fixed={pricing.sharp.fixed} />;
          }
          if (
            location.pathname === '/realizacje' ||
            location.pathname === '/realizacje/'
          ) {
            return <SideBarImage fixed={portfolio.sharp.fixed} />;
          }
          if (
            location.pathname === '/kontakt' ||
            location.pathname === '/kontakt/'
          ) {
            return <SideBarImage fixed={contact.sharp.fixed} />;
          }
          return <SideBarImage fixed={pricing.sharp.fixed} />;
        }}
      </Location>
      <Header />
    </SideBarWrapper>
  );
};

export default sideBar;
