import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { theme } from 'utils/theme/theme';
import ContactHeader from 'components/contactHeader/contactHeader';
import MobileHeader from 'components/mobileHeader/mobileHeader';
import SideBar from 'components/sideBar/sideBar';
import 'assets/css/fonts.css';
import {
  GlobalStyle,
  SiteWrapper,
  ContentWrapper,
  HomeContent,
  AboutUsContent,
  OfferContent,
  PricingContent,
  PortfolioContent,
  ContactContent,
  NotFoundContent,
} from './layout.styled';

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <SiteWrapper>
          <SideBar />
          <MobileHeader />
          <ContentWrapper>
            <ContactHeader />
            <Location>
              {({ location }) => {
                if (location.pathname === '/') {
                  return <HomeContent>{children}</HomeContent>;
                }
                if (
                  location.pathname === '/o-nas' ||
                  location.pathname === '/o-nas/'
                ) {
                  return <AboutUsContent>{children}</AboutUsContent>;
                }
                if (
                  location.pathname === '/oferta' ||
                  location.pathname === '/oferta/'
                ) {
                  return <OfferContent>{children}</OfferContent>;
                }
                if (
                  location.pathname === '/cennik' ||
                  location.pathname === '/cennik/'
                ) {
                  return <PricingContent>{children}</PricingContent>;
                }
                if (
                  location.pathname === '/realizacje' ||
                  location.pathname === '/realizacje/'
                ) {
                  return <PortfolioContent>{children}</PortfolioContent>;
                }
                if (
                  location.pathname === '/kontakt' ||
                  location.pathname === '/kontakt/'
                ) {
                  return <ContactContent>{children}</ContactContent>;
                }
                return <NotFoundContent>{children}</NotFoundContent>;
              }}
            </Location>
          </ContentWrapper>
        </SiteWrapper>
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
