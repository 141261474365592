import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const StyledWrapper = styled.div`
  width: 160px;
  height: 23px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconLink = styled.a`
  text-decoration: none;
  transition: transform 0.3s ease-in-out;

  :hover {
    transform: scale(1.048);
  }
`;

const SocialIcon = styled(Image)`
  width: 20px;
  height: 20px;
`;

const SocialIcons = () => {
  const { facebook, instagram, behance } = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: { eq: "facebook.png" }) {
        sharp: childImageSharp {
          fixed(width: 20, quality: 100) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram.png" }) {
        sharp: childImageSharp {
          fixed(width: 20, quality: 100) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
      behance: file(relativePath: { eq: "behance.png" }) {
        sharp: childImageSharp {
          fixed(width: 20, quality: 100) {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <IconLink href="https://www.facebook.com/FactoryOfLook/" target="_blank">
        <SocialIcon fixed={facebook.sharp.fixed} />
      </IconLink>
      <IconLink href="https://www.instagram.com/factoryoflook/" target="_blank">
        <SocialIcon fixed={instagram.sharp.fixed} />
      </IconLink>
      <IconLink href="https://www.behance.net/FactoryofLook" target="_blank">
        <SocialIcon fixed={behance.sharp.fixed} />
      </IconLink>
    </StyledWrapper>
  );
};

export default SocialIcons;
