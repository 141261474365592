import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ContactIcons from 'components/contactIcons/contactIcons';
import SocialIcons from 'components/socialIcons/socialIcons';

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  display: none;
  align-items: center;
  justify-content: flex-end;
  z-index: 9998;
  flex-direction: column;
  transform: translateX(${({ isOpen }) => (isOpen ? '-100%' : '0')});
  transition: transform 0.25s ease-in-out;

  @media (max-width: 850px) {
    display: flex;
  }
`;

const HeaderWrapper = styled.div`
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MenuLinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ContactIconsWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  transform: translateX(${({ isOpen }) => (isOpen ? '-100%' : '0')});
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transition: transform 0.2s 0.2s ease-in-out, opacity 0.2s 0.2s ease-in-out;
`;

const SocialIconsWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(${({ isOpen }) => (isOpen ? '200%' : '0')});
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transition: transform 0.25s 0.2s ease-in-out, opacity 0.15s 0.2s ease-in-out;
`;

const MenuItems = styled.li`
  text-align: center;
  list-style: none;
  margin: 20px 0;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transition: opacity 0.25s 0.2s ease-in-out;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grey};
`;
const MobileMenu = ({ isOpen }) => (
  <StyledWrapper isOpen={isOpen}>
    <HeaderWrapper>
      <MenuLinksWrapper>
        <MenuItems isOpen={isOpen}>
          <MenuLink to="/">Home</MenuLink>
          <MenuItems isOpen={isOpen}>
            <MenuLink to="/o-nas">O nas</MenuLink>
          </MenuItems>
          <MenuItems isOpen={isOpen}>
            <MenuLink to="/oferta">Oferta</MenuLink>
          </MenuItems>
          <MenuItems isOpen={isOpen}>
            <MenuLink to="/cennik">Cennik</MenuLink>
          </MenuItems>
          <MenuItems isOpen={isOpen}>
            <MenuLink to="/realizacje">Realizacje</MenuLink>
          </MenuItems>
          <MenuItems isOpen={isOpen}>
            <MenuLink to="/kontakt">Kontakt</MenuLink>
          </MenuItems>
        </MenuItems>
      </MenuLinksWrapper>
      <ContactIconsWrapper isOpen={isOpen}>
        <ContactIcons isOpen={isOpen} />
      </ContactIconsWrapper>
      <SocialIconsWrapper isOpen={isOpen}>
        <SocialIcons isOpen={isOpen} />
      </SocialIconsWrapper>
    </HeaderWrapper>
  </StyledWrapper>
);

MobileMenu.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default MobileMenu;
